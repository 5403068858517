import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Icon as ResetIcon } from 'images/utility-icons/reset.svg'
import { Icon as DocumentIcon } from 'images/document-new.svg'
import { ClickableTooltip } from 'components'
import * as Types from 'types'

const propTypes = {
  handleUpload: PropTypes.func,
  participantsForSubmission: PropTypes.arrayOf(Types.rolloverParticipant),
}

const defaultProps = {
  handleUpload: null,
  participantsForSubmission: [],
}

function ParticipantsSummaryContainer({
  handleUpload,
  participantsForSubmission,
}) {
  return (
    <div className="file-name-container">
      <div className="document">
        <div className="document-icon-wrapper">
          <DocumentIcon aria-hidden="true" />
        </div>
        <p>You've submitted {participantsForSubmission.length} participants</p>
      </div>
      {handleUpload && (
        <ClickableTooltip
          content="Remove"
          onClick={handleUpload}
          className="action-button"
          ariaLabel="Remove"
        >
          <ResetIcon aria-hidden="true" />
        </ClickableTooltip>
      )}
    </div>
  )
}

ParticipantsSummaryContainer.propTypes = exact(propTypes)
ParticipantsSummaryContainer.defaultProps = defaultProps

export default React.memo(ParticipantsSummaryContainer)
