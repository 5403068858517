import React, { useRef, useState } from 'react'
import {
  useFlatfile,
  Space,
  useListener,
  Workbook,
  Document,
} from '@flatfile/react'
import {
  RecordNames,
  RecordTypes,
  getFlatfileSchema,
  UploadInstructions,
} from 'flatfile-config'
import { convertHook } from '@flatfile/v2-shims'
import api from '@flatfile/api'
import { ParticipantsSummaryContainer } from 'components'
import { recordHook } from '@flatfile/plugin-record-hook'
import { isEmpty, noop, has, toArray } from 'lodash'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { selectors as globalSelectors } from '../../js/main/reducer'
import { listeners } from '../listeners'
import { compose } from 'redux'
import { connect } from 'react-redux'
import * as Types from 'types'

const propTypes = {
  recordType: PropTypes.oneOf(Object.values(RecordTypes)).isRequired,
  constraints: PropTypes.object,
  buttonContent: PropTypes.node.isRequired,
  fileName: PropTypes.string,
  setFileName: PropTypes.func,
  handleRecords: PropTypes.func,
  handleRecordsSubmit: PropTypes.func,
  handleValidData: PropTypes.func.isRequired,
  handleInvalidData: PropTypes.func.isRequired,
  currentUser: Types.user.isRequired,
  participantsForSubmission: PropTypes.arrayOf(Types.rolloverParticipant),
}

const defaultProps = {
  handleRecords: noop,
  handleRecordsSubmit: noop,
  fileName: null,
  setFileName: noop,
  participantsForSubmission: [],
}

/**
 * This function finds missing fields from the list of records using the list of required field keys
 *
 * @param {object} record
 * @param {string[]} fields
 * @returns {string[]} a list missing fields
 */
function findMissingFields(record, fields) {
  return fields.filter((field) => !has(record, field))
}

/**
 * Given an array of records and an array of fields, assign each field with an empty string to each record:
 * @param {object[]} records
 * @param {string[]} fields
 * @returns {*}
 */
function assignFields(records, fields) {
  return fields.reduce((accumulatedRecords, field) => {
    return accumulatedRecords.map((record) => ({
      ...record,
      [field]: '',
    }))
  }, records)
}

const FlatfileUpload = ({
  recordType,
  constraints,
  buttonContent,
  handleRecordsSubmit,
  handleRecords,
  fileName,
  setFileName,
  handleValidData,
  handleInvalidData,
  currentUser,
  participantsForSubmission,
}) => {
  const { open, openPortal, closePortal } = useFlatfile()
  const isUploadComplete = useRef(false)
  const uploadedFileName = useRef('')
  const [fileId, setFileId] = useState('')
  const userAudience = currentUser.accountAudience

  const toggleFlatfileUpload = () => {
    if (!open) {
      openPortal()
    } else {
      closePortal()
    }
  }

  const flatfileConfig = {
    ...getFlatfileSchema(recordType, constraints, userAudience),
    actions: [
      {
        operation: 'submitAction',
        mode: 'foreground',
        label: 'Continue',
        type: 'string',
        description: 'Submit data',
        primary: true,
        constraints: [{ type: 'hasData' }],
      },
    ],
  }

  const fieldKeys = flatfileConfig.sheets[0].fields.map((field) => field.key)

  useListener((listener) => {
    listener.on(
      'job:ready',
      {
        job: 'workbook:submitAction',
      },
      async (event) => {
        const { jobId, workbookId, spaceId } = event.context
        const { data: workbookSheets } = await api.sheets.list({ workbookId })
        const fileName = (await api.files.get(fileId)).data.name
        uploadedFileName.current = fileName

        try {
          workbookSheets.forEach(async (sheet) => {
            const data = await api.records.get(sheet.id)

            await api.jobs.ack(jobId, {
              info: 'Starting job to process the submitted record',
              progress: 10,
            })
            const { validData, invalidData } = data.data.records.reduce(
              (acc, { values, valid }, index) => {
                const transformedValues = Object.fromEntries(
                  toArray(values).map((curr, index) => [
                    fieldKeys[index],
                    curr.value ?? '',
                  ])
                )
                const record = { ...transformedValues, row: ++index }
                if (valid) acc.validData.push(record)
                else acc.invalidData.push(record)
                return acc
              },
              { validData: [], invalidData: [] }
            )
            setFileName(uploadedFileName.current)

            const missingFields = findMissingFields(validData[0], fieldKeys)
            const validDataWithAllFields = assignFields(
              validData,
              missingFields
            )

            await api.jobs.ack(jobId, {
              info: 'Job is completed',
              progress: 100,
            })

            const validDataExists = !isEmpty(validData)
            const invalidDataExists = !isEmpty(invalidData)

            if (validDataExists && !invalidDataExists) {
              isUploadComplete.current = validDataExists
              await api.jobs.complete(jobId, {
                outcome: {
                  acknowledge: true,
                  message: `Success! your ${RecordNames[
                    recordType
                  ].toLowerCase()} information has been captured`,
                },
              })
              handleValidData(validDataWithAllFields)
              handleRecordsSubmit && handleRecordsSubmit()
              closePortal()
            } else if (invalidDataExists && validDataExists) {
              handleInvalidData(invalidData)
              await api.jobs.complete(jobId, {
                outcome: {
                  acknowledge: true,
                  hideDefaultButton: true,
                  next: {
                    type: 'id',
                    id: spaceId,
                    path: `workbook/${workbookId}/sheet/${sheet.id}`,
                    query: 'filter=error',
                    label: 'Review invalid rows',
                  },
                  heading:
                    'You have one or more rows with unresolved formatting issues.',
                  message: `Click on the Go to invalid records button and fix the invalid record before submitting again.\n You can delete the invalid records.`,
                },
              })
            } else {
              await api.jobs.fail(jobId, {
                outcome: {
                  message: `No valid records were submitted. Please try again`,
                },
              })
            }
          })
        } catch (error) {
          await api.jobs.fail(jobId, {
            outcome: {
              message: `No valid records were submitted. Please try again. ${error}`,
            },
          })
          return
        }
      }
    )

    listener.on('upload:completed', async ({ context: { fileId } }) => {
      setFileId(fileId)
    })

    listener.use(
      recordHook(flatfileConfig.sheets[0].slug, (record) => {
        return convertHook(handleRecords)(record)
      })
    )
  })

  // Apply specific module listeners
  useListener((listener) => listeners[recordType](listener))

  return (
    <>
      {fileName || participantsForSubmission?.length ? (
        <ParticipantsSummaryContainer
          participantsForSubmission={participantsForSubmission}
          handleUpload={() => {
            toggleFlatfileUpload()
          }}
        />
      ) : (
        <button
          className="button-primary-outline upload-file is-fullwidth"
          onClick={toggleFlatfileUpload}
        >
          {buttonContent}
        </button>
      )}

      <Space
        config={{
          name: `${RecordNames[recordType]} Record`,
          metadata: {
            sidebarConfig: {
              showSidebar: false,
            },
          },
        }}
      >
        <Document
          config={{
            title: 'Upload Instructions',
            body: UploadInstructions[recordType],
          }}
        />
        <Workbook config={flatfileConfig} />
      </Space>
    </>
  )
}

function mapStateToProps(state) {
  return {
    currentUser: globalSelectors.currentUser(state),
  }
}

const mapDispatchToProps = {}

FlatfileUpload.propTypes = exact(propTypes)
FlatfileUpload.defaultProps = defaultProps

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  React.memo(FlatfileUpload)
)
