import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  Accordion,
  ExternalLink,
  Notification,
  ScrollErrorForm,
  ConfirmationCheckbox,
} from 'components'
import { Variant as NotificationVariant } from 'components/Notification'
import {
  getParticipantFileUploadExpandedItems,
  setParticipantFileUploadExpandedItems,
} from 'local-storage'
import { RecordTypes } from 'flatfile-config'
import { useHistory, useLocation, Redirect } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { Spinner } from 'lp-components'
import {
  formatAndValidateParticipantRecord,
  formatAndValidateUCHParticipantRecords,
  useParticipantsSummary,
} from 'utils'
import * as actions from '../actions'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import { FlowActions } from '../../products/components'
import { ParticipantsSummaryTable } from '../components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { COMPANY_NAME, Path } from 'config/portal'
import { ReasonForDiscard, RolloverType } from 'config/automatic-rollovers'
import { Icon as DownloadIcon } from 'images/download.svg'
import { FlatfileProvider } from '@flatfile/react'
import FlatfileUpload from '../../../components/FlatfileUpload'
import { Icon as ArrowDownIcon } from 'images/utility-icons/arrow-down.svg'

const propTypes = {
  legaleseConfirmationForm: PropTypes.object,
  setLegaleseConfirmationForm: PropTypes.func.isRequired,
  participantsForSubmission: PropTypes.arrayOf(Types.rolloverParticipant)
    .isRequired,
  setParticipantsForSubmission: PropTypes.func.isRequired,
  resetDiscardedParticipants: PropTypes.func.isRequired,
  participantFileName: PropTypes.string,
  setParticipantFileName: PropTypes.func.isRequired,
  fetchCompletedServicesAgreements: PropTypes.func.isRequired,
  completedServicesAgreements: PropTypes.arrayOf(
    Types.servicesAgreementSummary
  ),
  rolloverType: PropTypes.string,
}

const defaultProps = {
  legaleseConfirmationForm: {},
  completedServicesAgreements: null,
  participantFileName: null,
}
const accordionItems = [
  {
    uuid: 'instructions',
    heading: '',
    panel: (
      <>
        <p>
          Please follow the steps below to upload your participant data file.
          The participant data submission process works best if you use the EZ
          Worksheet Template above, but it is not required.
        </p>
        <ol className="instructions-list">
          <li>
            After clicking on Upload File below, you will be asked to start the
            upload process by selecting your file.
          </li>
          <li>
            Select the file and then choose the name of the tab that your data
            is contained in, if applicable.
          </li>
          <li>
            Confirm the "Header Row" in your file where the names of the columns
            are specified. The row immediately after the header row must be the
            first row of participant data.
          </li>
          <li>
            Match the column names from your file to the column names that{' '}
            {COMPANY_NAME['long']} requires for submission. If you are missing
            any required columns, you will be notified of this when you attempt
            to proceed to the next step. If you use the template file, the
            system will auto-match all the headers and you only need to review
            and advance to the next step.
          </li>
          <li>
            Review the rows of participant data provided and resolve any issues
            identified by the system. Data from your original file may be
            reformatted by the system to a standard format (such as SSN, EIN, or
            Date of Birth). All cells highlighted in red must be corrected
            before you are able to proceed to the next step in the submission
            process. If you attempt to proceed without correcting all issues
            identified, the invalid rows will not be included in the submission
            to {COMPANY_NAME['long']}.
          </li>
        </ol>
        <p>
          Note: If you find that your file has many issues, it may be faster to
          make edits to the file offline in Excel then upload again when the
          changes have been made.
        </p>
      </>
    ),
  },
]

function UploadParticipantFileView({
  legaleseConfirmationForm,
  setLegaleseConfirmationForm,
  participantsForSubmission,
  setParticipantsForSubmission,
  resetDiscardedParticipants,
  participantFileName,
  setParticipantFileName,
  fetchCompletedServicesAgreements,
  completedServicesAgreements,
  rolloverType,
}) {
  const history = useHistory()
  const location = useLocation()
  const [errorMessage, setErrorMessage] = useState(null)
  const [error, setError] = useState(null)
  const formRef = useRef()
  const { data: participantsSummaryData } = useParticipantsSummary(
    participantsForSubmission
  )
  const [expandedItems, setExpandedItems] = useState(
    getParticipantFileUploadExpandedItems() || []
  )
  const [showAccordion, setShowAccordion] = useState(false)

  useEffect(() => {
    async function getCompletedServicesAgreements() {
      try {
        await fetchCompletedServicesAgreements()
      } catch {
        setError(true)
      }
    }
    getCompletedServicesAgreements()
  }, [])

  useEffect(() => {
    if (!isEmpty(participantsForSubmission)) {
      setShowAccordion(false)
    }
  }, [participantsForSubmission])

  const handleToggleAccordion = () => {
    setShowAccordion(!showAccordion)
  }

  const handleSubmit = async (values) => {
    // if, for instance, flatfile error exists, do nothing
    if (errorMessage) return
    if (isEmpty(participantsForSubmission)) {
      setErrorMessage('Upload a valid file to continue')
      return
    }
    setParticipantFileUploadExpandedItems(expandedItems) // set expanded items in localStorage
    setLegaleseConfirmationForm(values)
    history.push({
      pathname: '/automatic-rollovers/plan-mapping',
      state: {
        search: location.state?.search,
      },
    })
  }

  const handleRecords =
    rolloverType === RolloverType.STANDARD
      ? formatAndValidateParticipantRecord // Validations for Automatic Rollovers
      : formatAndValidateUCHParticipantRecords // Validations for Uncashed Checks

  const getRecordType =
    rolloverType === RolloverType.STANDARD
      ? RecordTypes.ARO_PARTICIPANT // Records for Automatic Rollovers
      : RecordTypes.UCH_PARTICIPANT // Records for Uncashed Checks

  if (!completedServicesAgreements && !error) {
    return (
      <div className="upload-participant-file-view-container">
        <Spinner />
      </div>
    )
  }

  if (error) {
    return (
      <Redirect
        push
        to={{
          pathname: '/error',
          state: {
            ignorePrompt: true, // No need to warn the user about leaving the flow
            search: location.state?.search,
          },
        }}
      />
    )
  }

  // Prevent the user from accessing the flow if the user 1) has no completed services agreements
  // or 2) did not go through the start-new-rollover modal from the table view first

  if (!completedServicesAgreements.length) {
    return (
      <Redirect
        push
        to={{
          pathname: Path.SERVICES_AGREEMENTS,
          state: {
            ignorePrompt: true,
            search: location.state?.search,
          },
        }}
      />
    )
  }
  if (!rolloverType) {
    return (
      <Redirect
        push
        to={{
          pathname: Path.SERVICES_AGREEMENTS,
          state: {
            ignorePrompt: true,
            search: location.state?.search,
          },
        }}
      />
    )
  }

  return (
    <div className="card full-height upload-participant-file-view-container">
      <div className="form-masthead">
        <h2>Start by adding your participants</h2>
      </div>
      <div className="form-section form-section--new-spreadsheet">
        <div className="form-card-content upload-container">
          <div className="form-header">
            <p>
              You can add participants by manually completing our online
              participant file or by uploading the participant data spreadsheet.
            </p>
            <Notification type={NotificationVariant.WARNING}>
              <p className="text-title--small">
                Make sure you have a completed services agreement on file for
                your participants' corresponding plan before initiating a
                rollover.
              </p>
            </Notification>
          </div>
          <FlatfileProvider
            publishableKey={process.env.REACT_APP_FLATFILE_PK}
            config={{
              preload: true,
              resetOnClose: true,
            }}
          >
            <FlatfileUpload
              recordType={getRecordType}
              buttonContent="Add participant information"
              handleRecords={handleRecords}
              setFileName={setParticipantFileName}
              fileName={participantFileName}
              handleValidData={(validData) => {
                setParticipantsForSubmission(validData)
              }}
              handleInvalidData={(discardedData) => {
                const discardedParticipantsWithReason = discardedData.map(
                  (participant) => ({
                    ...participant,
                    reasonForDiscard: ReasonForDiscard.INVALID_DATA,
                  })
                )
                resetDiscardedParticipants(discardedParticipantsWithReason)
              }}
              participantsForSubmission={participantsForSubmission}
            />
          </FlatfileProvider>

          {!!errorMessage && (
            <span className="error-message">{errorMessage}</span>
          )}
          {!isEmpty(participantsSummaryData) && (
            <ParticipantsSummaryTable
              data={participantsSummaryData}
              title={<h4>Participant file summary</h4>}
              rolloverType={rolloverType}
            />
          )}
        </div>
      </div>
      <div className="form-section form-section--new-spreadsheet">
        <h3>File upload instructions</h3>
        <div className="upload-instructions">
          <p>1. Download the participant data spreadsheet.</p>
          <ExternalLink
            link={
              rolloverType === RolloverType.STANDARD
                ? process.env.REACT_APP_ARO_EZ_WORKSHEET_TEMPLATE_URL // standard
                : process.env.REACT_APP_UNCASHED_CHECKS_TEMPLATE_URL // uncashed check
            }
            className="link-text"
            aria-label="New Participant Data File Spreadsheet"
          >
            Participant data spreadsheet
          </ExternalLink>
          <DownloadIcon aria-hidden="true" />
          <p>2. Complete the template and upload it above. </p>
          <button className="button-text" onClick={handleToggleAccordion}>
            View template instructions
          </button>
          <ArrowDownIcon aria-hidden="true" />
        </div>
        {showAccordion && (
          <Accordion
            items={accordionItems}
            initialItemsExpanded={['instructions']}
            onChange={(itemUuids) => setExpandedItems(itemUuids)}
          />
        )}
      </div>
      <div className="form-section">
        <div className="form-header">
          <h3 className="is-marginless">
            Important information: review before continuing
          </h3>
        </div>
        <p>
          Generally, participants of terminating defined benefit plans are not
          eligible for rollover, unless the plan is not covered by the Pension
          Benefit Guaranty Corporation (PBGC). This includes distributions made
          in contemplation of plan termination but before the formal
          commencement of termination proceedings. It is the plan
          administrator's responsibility to determine whether the plan is
          covered by the PBGC and whether participant accounts are eligible for
          rollover.
        </p>
        <p>
          Active defined benefit and active defined contribution plan
          participant accounts may be eligible for rollover when the present
          value of the participant's nonforfeitable accrued benefit does not
          exceed $7,000. In limited circumstances, accounts with accrued
          benefits above $7,000 may be eligible for rollover. It is the plan
          administrator's responsibility to determine eligibility for each
          participant.
        </p>
        <Formik
          initialValues={{
            confirm: false,
            ...legaleseConfirmationForm,
          }}
          onSubmit={handleSubmit}
          validationSchema={Yup.object({
            confirm: Yup.boolean()
              .required('Required')
              .oneOf([true], 'Required'),
          })}
          innerRef={formRef}
        >
          <ScrollErrorForm>
            <ConfirmationCheckbox
              name="confirm"
              label="I acknowledge that I have read and understand the information above."
            />
          </ScrollErrorForm>
        </Formik>
      </div>
      <FlowActions
        handleSubmit={() => formRef.current.handleSubmit()}
        handleBack={() =>
          history.push('/automatic-rollovers/select-rollover-type')
        }
      />
    </div>
  )
}

UploadParticipantFileView.propTypes = propTypes

UploadParticipantFileView.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    legaleseConfirmationForm: selectors.legaleseConfirmationForm(state),
    participantsForSubmission: selectors.participantsForSubmission(state),
    participantFileName: selectors.participantFileName(state),
    completedServicesAgreements: selectors.completedServicesAgreements(state),
    rolloverType: selectors.rolloverType(state),
  }
}

const mapDispatchToProps = {
  setLegaleseConfirmationForm: actions.setLegaleseConfirmationForm,
  setParticipantsForSubmission: actions.setParticipantsForSubmission,
  resetDiscardedParticipants: actions.resetDiscardedParticipants,
  setParticipantFileName: actions.setParticipantFileName,
  fetchCompletedServicesAgreements: apiActions.fetchCompletedServicesAgreements,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  UploadParticipantFileView
)
